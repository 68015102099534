module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'DRM Photography', // Navigation and Site Title
  siteTitleAlt: 'Dharma Ratna Manandhar Photography Page', // Alternative Site title for SEO
  siteTitleShort: 'DRM Photography', // short_name for manifest
  siteHeadline: 'Publishing & Creating stunning photos', // Headline for schema.org JSONLD
  siteUrl: 'https://dharmamanandhar.com.np', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logos/logo.png', // Used for SEO and manifest
  siteDescription: 'Dark One-Page portfolio with cards & detailed project views',
  author: 'Dharma Ratna Manandhar', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@', // Twitter Username
  ogSiteName: 'dharma.r.manandhar', // Facebook Site Name
  ogLanguage: 'en_US', // og:language
  googleAnalyticsID: 'UA-128303089-1',

  // Manifest and Progress color
  themeColor: '#3498DB',
  backgroundColor: '#2b2e3c',

  // Your information
  avatar: '/logos/lekoarts.png',
  name: 'Dharma Ratna Manandhar',
  location: 'Nepal',
  socialMedia: [
    {
      url: 'https://www.facebook.com/dharma.r.manandhar',
      name: 'Facebook',
    },
    {
      url: 'https://www.instagram.com/drm015',
      name: 'Instagram',
    },
    {
      url: 'https://dharmamanandhar.com.np',
      name: 'Homepage',
    },
  ],
}
